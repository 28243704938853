<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    :dependentLinks="dependentLinks"
    @save="onSave('BasePhoneMatching')"
    @cancel="onCancel('BasePhoneMatching')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'BasePhoneMatchingEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      controllerName: 'BasePhones',
      pageHeader: 'Редактирование соответствия базы и телефона для роботов',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Соответствие баз и телефонов для роботов',
          route: { name: 'BasePhoneMatching' },
        },
        {
          text: 'Редактирование соответствия базы и телефона для роботов',
        },
      ],
      form: {},
      isLoadingPage: false,
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      baseList: (state) => state.common.sites,
    }),

    baseNameList() {
      const originList = this.baseList?.data || [];
      return originList.map((base) => {
        return {
          ...base,
          value: base.name,
        };
      });
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'phoneNumber',
              label: 'Номер телефона',
              attributes: {
                mask: '7##########',
                placeholder: '7__________',
              },
            },
            // {
            //   type: 'text',
            //   key: 'databaseName',
            //   label: 'Название базы данных',
            // },
            {
              type: 'select',
              key: 'databaseName',
              label: 'Название базы данных',
              list: this.baseNameList,
            },
            {
              type: 'integerNumber',
              key: 'parallelLineCount',
              label: 'Количество параллельных линий',
              attributes: {
                options: {
                  minimumValue: 0,
                  decimalPlaces: 0,
                },
              },
            },
            {
              type: 'text',
              key: 'comment',
              label: 'Комментарий',
            },
            {
              type: 'text',
              key: 'lastChanged',
              label: 'Дата и время последнего изменения',
              disabled: true,
            },
          ],
        },
      ];
    },
  },

  async created() {
    this.isLoadingPage = true;
    try {
      await this.getItem({ name: this.controllerName, id: this.id });
      await this.getCommonList({ name: 'Sites' });

      this.form = this.lodash.cloneDeep(this.item.data);
    } catch (error) {
      console.error('Ошибка загрузки данных:', error);
    } finally {
      this.isLoadingPage = false;
    }
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
